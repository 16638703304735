<template>
  <v-sheet>
    <v-form ref="form">
      <div v-if="menuHistory">
        <v-card-text>
          <div>
            <div class="custom-header">
              <v-btn @click="Home" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

              <div class="header-title">History</div>
            </div>
          </div>
          <br />
          <br />
          <br />

          <v-autocomplete
            v-model="selectedItem"
            :items="items"
            :loading="loading"
            :search-input.sync="search"
            :no-filter="true"
            item-text="status_pickup"
            item-value="id"
            @blur="onBlur"
            label="Status"
            clearable
            outlined
          ></v-autocomplete>

          <div v-if="isLoading" class="loader-overlay"></div>
          <div class="d-flex flex-column">
            <v-btn color="warning" @click="getDataTable">
              <v-icon left>mdi-filter</v-icon>Filter
            </v-btn>
          </div>
          <v-divider class="mx-4 mb-1"></v-divider>
          <div v-for="item in items_data" :key="item.pickup_id">
            <v-row dense>
              <v-col cols="12">
                <v-card>
                  <v-card-title
                    style="font-size: 16px"
                    @click="showLacak(item)"
                    class="no-padding"
                    >#{{ item.pickup_id }} {{ item.nama_komoditi }} <br />{{
                      item.tgl_pickup
                    }}
                    <v-divider></v-divider
                    ><v-chip-group>
                      <v-chip color="red" text-color="white">{{
                        item.status_pickup
                      }}</v-chip>
                    </v-chip-group>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" align="right" class="no-padding"
                        >x{{
                          Number(item.qty_pickup).toLocaleString("id-ID")
                        }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        align="right"
                        class="no-padding"
                        style="font-size: 16px; color: red"
                        >Rp.{{
                          Number(item.harga_pickup).toLocaleString("id-ID")
                        }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        align="right"
                        class="no-padding"
                        style="font-size: 20px; color: red"
                        >Total Penjualan : Rp.{{
                          Number(item.total_pickup).toLocaleString("id-ID")
                        }}</v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <br />
          <br />
          <br />
          <div v-if="data">
            <img
              :src="not_found"
              alt="Photo"
              :style="{ width: '300px', height: 'auto' }"
            />
          </div>
        </v-card-text>
      </div>
      <div v-if="menuLacak">
        <v-card-text>
          <div>
            <div class="custom-header">
              <v-btn @click="ShowHistory" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="header-title">Rincian Status</div>
            </div>
            <br />
            <br />
            <v-row>
              <v-alert density="compact" type="warning" title="Alert title">
                {{ status }} <br />
                {{ keterangan }}
              </v-alert>
            </v-row>
            <v-row>
              <v-alert
                density="compact"
                title="Alert title"
                icon="mdi-google-maps"
              >
                Alamat Pengiriman
                <br />
                {{ nama }}
                <br />
                {{ no_hp }}
                <br />
                {{ alamat_pickup }}
              </v-alert>
            </v-row>
            <div v-if="menuDownline">
              <v-row>
                <v-alert
                  density="compact"
                  title="Alert title"
                  icon="mdi-google-maps"
                >
                  Informasi Referal
                  <br />
                  {{ nama_referal }}
                  <br />
                  <a
                    target="_blank"
                    :href="'https://api.whatsapp.com/send?phone=' + no_hp_referal"
                    >{{ no_hp_referal }}</a
                  >
                  <br />
                  <a
                    target="_blank"
                    :href="
                      'https://maps.google.com?daddr=' +
                      latitude +
                      ',' +
                      longitude
                    "
                    >{{ alamat_referal }}
                  </a
                  >
                </v-alert>
              </v-row>
            </div>
            <div v-if="menuDriver">
              <v-row>
                <v-alert density="compact" title="Alert title" icon="mdi-truck">
                  Informasi Driver
                  <br />
                  {{ nama_driver }}
                  <br />
                  <a
                    target="_blank"
                    :href="
                      'https://api.whatsapp.com/send?phone=' + no_hp_driver
                    "
                    >{{ no_hp_driver }}</a
                  >
                  <br />
                  <a
                    target="_blank"
                    :href="
                      'https://mygps.ptppi.co.id/track?plate=' +
                      plat_no +
                      '&blank=1'
                    "
                    >Lacak</a
                  >
                </v-alert>
              </v-row>
            </div>

            <div v-if="menuKonfirmasi">
              <v-row>
                <v-alert
                  density="compact"
                  title="Alert title"
                  icon="mdi-credit-card"
                >
                  Konfirmasi Penerimaan
                  <br />
                  <div v-if="keterangan_perubahan">
                    Qty : {{ Number(qty_pickup).toLocaleString("id-ID") }}
                    <br />
                    Harga : {{ Number(harga_pickup).toLocaleString("id-ID") }}
                    <br />
                    Total : Rp.{{
                      Number(total_pickup).toLocaleString("id-ID")
                    }}
                    <br />
                    Keterangan Perubahan :
                    <br />
                    {{ keterangan_perubahan }}
                    <br />
                  </div>
                  <div v-else>
                    Qty : {{ Number(qty_pickup).toLocaleString("id-ID") }}
                    <br />
                    Harga : {{ Number(harga_pickup).toLocaleString("id-ID") }}
                    <br />
                    Total : Rp.{{
                      Number(total_pickup).toLocaleString("id-ID")
                    }}
                    <br />
                  </div>
                </v-alert>
              </v-row>
            </div>

            <div v-if="menuBayar">
              <v-row>
                <v-alert
                  density="compact"
                  title="Alert title"
                  icon="mdi-credit-card"
                >
                  Informasi Pembayaran
                  <br />
                  <a
                    target="_blank"
                    :href="
                      'https://woles.ptppi.co.id/api/uploads/' +
                      bukti_pembayaran
                    "
                    >Bukti Pembayaran</a
                  >
                  <br />
                </v-alert>
              </v-row>
            </div>
            <v-row>
              <v-timeline dense>
                <v-timeline-item
                  v-for="item in items_"
                  :key="item.pickup_id"
                  small
                  fill-dot
                >
                  <div>
                    <div
                      v-if="item.flag === '0'"
                      style="font-size: 12px; color: grey"
                    >
                      {{ item.status_pickup }}
                    </div>
                    <div
                      v-else
                      style="font-size: 12px; color: black; font-style: bold"
                    >
                      {{ item.updated_at }}
                      {{ item.status_pickup }}
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-row>
          </div>
        </v-card-text>
      </div>
      <br />
      <br />
      <br />
    </v-form>
  </v-sheet>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      selectedItem: null,
      items: [],
      search: "",
      loading: false,

      selectedProduct: null,
      products: [],
      searchProduct: "",

      isLoading: false,

      menuHistory: true,
      menuLacak: false,
      menuDriver: false,
      menuBayar: false,
      menuDownline: false,
      menuKonfirmasi: false,
      status: "",
      keterangan: "",
      nama: "",
      no_hp: "",
      alamat_pickup: "",
      pickup_id: "",
      plat_no: "",
      nama_driver: "",
      no_hp_driver: "",
      status_pickup_id: "",
      bukti_pembayaran: "",
      latitude: "",
      longitude: "",
      no_referal: "",
      nama_referal : "",
      alamat_referal: "",
      items_: [],
      keterangan_perubahan: "",

      items_data: [],
      data: false,
      m_users_id: localStorage.getItem("ID"),
      role_id: localStorage.getItem("role_id"),
      url: localStorage.getItem("url"),
      MediaKeys: localStorage.getItem("key"),
      not_found:
        "https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg?size=626&ext=jpg&ga=GA1.1.1219781391.1697861332&semt=ais",
    };
  },
  created: function () {},
  mounted() {
    this.getDataTable()
  },

  methods: {
    onBlur() {
      if (!this.selectedItem) {
        this.search = "";
      }
      if (!this.selectedProducts) {
        this.searchProduct = "";
      }
    },
    async searchItems() {
      this.loading = true;
      this.items = await this.fetchStatusFromDatabase(
        this.search,
        this.unit_name,
        this.url,
        this.MediaKeys
      );
      this.loading = false;
    },
    showLacak(item) {
      this.menuLacak = true;
      this.menuHistory = false;
      this.status = item.status_pickup;
      this.status_pickup_id = item.status_pickup_id;
      this.keterangan = item.keterangan;
      this.nama = item.nama;
      this.no_hp = item.no_hp;
      this.alamat_pickup = item.alamat_pickup;
      this.pickup_id = item.pickup_id;

      if (this.status_pickup_id === "4") {
        this.plat_no = item.plat_no;
        this.nama_driver = item.nama_driver;
        this.no_hp_driver = item.no_hp_driver;
        this.menuDriver = true;
      } else if (this.status_pickup_id === "6") {
        this.keterangan_perubahan = item.keterangan_perubahan;
        this.qty_pickup = item.qty_pickup;
        this.harga_pickup = item.harga_pickup;
        this.total_pickup = item.total_pickup;
        this.menuKonfirmasi = true;
      } else if (this.status_pickup_id === "8") {
        this.bukti_pembayaran = item.bukti_pembayaran;
        this.menuBayar = true;
      } else if (this.status_pickup_id === "0") {
        this.bukti_pembayaran = item.bukti_pembayaran;
        this.latitude = item.latitude;
        this.longitude = item.longitude;
        this.no_referal = item.no_referal;
        this.alamat_referal = item.alamat_referal;
        this.nama_referal = item.nama_referal;
        this.menuDownline = true;
      } else {
        this.menuDriver = false;
        this.menuBayar = false;
        this.menuKonfirmasi = false;
        this.menuDownline = false;
      }

      this.getDetilDataTable();
    },
    Home() {
      this.$router.push("/");
    },
    ShowHistory() {
      this.menuLacak = false;
      this.menuHistory = true;
    },

    async getDataTable() {
      this.isLoading = true; // Mengaktifkan loader
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_history",
          {
            status_id: this.selectedItem,
            m_users_id: this.m_users_id,
            role_id: this.role_id,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data.status);
        if (response.data.status === "fail") {
          //this.$router.push("/customer");
          this.items_data = [];
          this.data = true;
        } else {
          this.data = false;
          this.items_data = response.data.data;
          //this.items_ = response.data.data;

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getDetilDataTable() {
      //this.isLoading = true; // Mengaktifkan loader
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_detil_history",
          {
            pickup_id: this.pickup_id,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data.status);
        if (response.data.status === "fail") {
          //this.$router.push("/customer");
          this.items_ = [];
        } else {
          this.items_ = response.data.data;

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
        //this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchStatusFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_status", {
        params: { search },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },
  },
  watch: {
    search: function () {
      this.searchItems();
    },
    searchProduct: function () {
      this.searchProducts();
    },
  },
};
</script>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px; /* Adjust the height as needed */
  background-color: #ffffff;
  color: black;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 1000;
}

.header-title {
  font-size: 18px;
  font-weight: bold;
}
</style>

<style scoped>
.no-padding {
  padding: 0; /* or padding: 0 !important; if needed */
}
</style>
                

