import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "bootstrap/dist/css/bootstrap.min.css";
import { gmapApi } from "vue2-google-maps";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import swal from "sweetalert";
// import  OneSignal   from 'onesignal-cordova-plugin';


Vue.config.productionTip = false;
const firebaseConfig = {
  apiKey: "AIzaSyD2y0uylVf92WvBm105xhI9i11WhsF9koU",
  authDomain: "webapps-47f3f.firebaseapp.com",
  projectId: "webapps-47f3f",
  storageBucket: "webapps-47f3f.appspot.com",
  messagingSenderId: "736931850508",
  appId: "1:736931850508:web:7ee4121bad64e995e10129",
  measurementId: "G-6LFZV2SCMG",
};

initializeApp(firebaseConfig);

new Vue({
  router,
  vuetify,
  gmapApi,
  render: (h) => h(App),
  mounted() {
    // OneSignal.initialize("66ae5b84-5ac9-4b7e-805f-f5a6b3adb471");
    // OneSignal.Notifications.requestPermission();

    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // ...
    });

    getToken(messaging, {
      vapidKey:
        "BHCsFsoFGtmG_QK5asHsnZHF0RZebBSuJDZLcIYpw88Xb4Mnr1C0rcE88BhP-_cZIMr4PItXKWsl6srKkYVBSlg",
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log("Token Main is:", currentToken);
          localStorage.setItem(
            "tokenFCM",
            "fi6zVV9L6s9EsfvsQ0QShK:APA91bGxGmkz2ooQ8JjfucQZXWiegiEnmPktapbGQZzVOPH_FnYZuOeIfUQ-qSi9i7Z4l9rhYmkU02-_nHTxPHhctpKt1U0Q_jHHarC3rkc8LUfpYbf8LWjJJeM6vrLRFlU9WVPTZtqD"
          );
          // ...
        } else {
          // Show permission request UI
          // swal({
          //   title: "Info",
          //   text: "Tolong Aktifkan Notifikasi Browser Anda",
          //   icon: "info",
          // });
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // );
          // ...
        }
      })
      .catch((err) => {
        // swal({
        //   title: "Info",
        //   text: "Tolong Aktifkan Notifikasi Browser Anda",
        //   icon: "info",
        // });
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  },
}).$mount("#app");
