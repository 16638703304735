<template>
  <v-sheet>
    <v-form ref="form">
      <v-card-text>
        <div v-if="formlogin">
          <div>
            <div class="custom-header">
              <v-btn @click="Home" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

              <div class="header-title">Profile</div>
            </div>
          </div>
          <br />
          <br />
          <br />

          <v-text-field
            outlined
            label="Nama Anda"
            :rules="required"
            v-model="nama"
          />

          <v-text-field
            outlined
            label="No. Handphone"
            :rules="required"
            v-model="no_hp"
            hint="Contoh, 62812131415"
            persistent-hint
          />

          <v-autocomplete
            v-model="selectedBank"
            :items="banks"
            :loading="loading"
            :search-input.sync="searchBank"
            :no-filter="true"
            item-text="bank"
            item-value="id"
            @blur="onBlur"
            label="List Bank"
            outlined
            clearable
          ></v-autocomplete>

          <v-text-field
            outlined
            label="No. Rekening"
            :rules="required"
            v-model="no_rekening"
          />

          <v-text-field
            outlined
            label="Atas Nama"
            :rules="required"
            v-model="atas_nama_rekening"
          />

          <v-text-field outlined label="NIK" :rules="required" v-model="nik" />
          <div v-if="imageUrl">
            <a :href="imageUrl" target="_blank">
              <img
                :src="imageUrl"
                alt="Photo"
                :style="{ width: '300px', height: 'auto' }"
              />
            </a>
          </div>
          <div v-else>
            <p>No image available</p>
          </div>
          <br />
          <br />
          <v-file-input
            v-model="file"
            show-size
            counter
            accept="image/*"
            label="Upload KTP"
            outlined
          ></v-file-input>

          <v-autocomplete
            v-model="selectedProvinsi"
            :items="provinsis"
            :loading="loading"
            :search-input.sync="searchProvinsi"
            :no-filter="true"
            item-text="provinsi"
            item-value="provinsi_id"
            @blur="onBlur"
            label="Provinsi"
            outlined
            clearable
          ></v-autocomplete>

          <v-autocomplete
            v-model="selectedKabupaten"
            :items="kabupatens"
            :loading="loading"
            :search-input.sync="searchKabupaten"
            :no-filter="true"
            item-text="kab_kota"
            item-value="kab_kota_id"
            @blur="onBlur"
            label="Kabupanten / Kota"
            outlined
            clearable
          ></v-autocomplete>

          <v-autocomplete
            v-model="selectedKecamatan"
            :items="kecamatans"
            :loading="loading"
            :search-input.sync="searchKecamatan"
            :no-filter="true"
            item-text="kecamatan"
            item-value="kecamatan_id"
            @blur="onBlur"
            label="Kecamatan"
            outlined
            clearable
          ></v-autocomplete>

          <v-autocomplete
            v-model="selectedKelurahan"
            :items="kelurahans"
            :loading="loading"
            :search-input.sync="searchKelurahan"
            :no-filter="true"
            item-text="kelurahan"
            item-value="daerah_id"
            @blur="onBlur"
            label="Kelurahan"
            outlined
            clearable
          ></v-autocomplete>

          <v-textarea
            outlined
            label="Alamat Sesuai KTP"
            v-model="alamat_ktp"
            :rules="required"
          ></v-textarea>
          <div v-if="user_status === '1' && role === '1'">
          <v-textarea
            outlined
            label="Alamat Pengambilan"
            v-model="alamat_pengambilan"
            :rules="required"
            append-icon="mdi-chevron-right"
            @click:append="Lokasi"
            readonly
          ></v-textarea>
        </div>

          <v-text-field
            outlined
            label="PIN"
            :type="ShowPassword ? 'number' : 'password'"
            :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="HandleClick"
            v-model="passwordawal"
            placeholder="Kosongkan jika tidak merubah PIN"
            maxlength = "6"
          />

          <v-text-field
            outlined
            label="Konfirmasi PIN"
            :type="ShowPassword ? 'number' : 'password'"
            :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="HandleClickKonfirm"
            v-model="konfirmasipassword"
            placeholder="Kosongkan jika tidak merubah PIN"
            maxlength = "6"
          />
          <div v-if="isLoading" class="loader-overlay"></div>
          <div class="d-flex flex-column">
            <v-btn color="warning" @click="Upload"> Simpan </v-btn>
            <!-- <v-divider></v-divider> -->
            <!-- <v-btn @click="Logout"> Keluar </v-btn> -->
            <br />
            <br />
            <br />
            <div class="text-center">
              <v-snackbar v-model="snackbar" :timeout="timeout">
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-form>
  </v-sheet>
</template>
  
  
  <script>
import axios from "axios";
// import swal from "sweetalert";

export default {
  name: "ProfileView",

  data() {
    return {
      ShowPassword: false,
      nama: localStorage.getItem("name_users"),
      no_hp: localStorage.getItem("no_hp"),
      ID: localStorage.getItem("ID"),
      url: localStorage.getItem("url"),
      MediaKeys: localStorage.getItem("key"),
      role: localStorage.getItem("role_id"),
      password: "",
      formdaftar: false,
      formlogin: true,
      nik: "",
      alamat_pengambilan : "",
      norules: [(v) => !!v || "No. Handphone is required"],
      //v => /.+@.+/.test(v) || "ID Karyawan must be valid"
      //v => /.+@.+/.test(v) || "ID Karyawan must be valid"
      numberRule: [
        (v) => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999999) return true;
          return "Number has to be between 0 and 99999";
        },
      ],
      required: [(v) => !!v || "required"],
      file: null,

      selectedProvinsi: null,
      provinsis: [],
      searchProvinsi: "",

      selectedKabupaten: null,
      kabupatens: [],
      searchKabupaten: "",

      selectedKecamatan: null,
      kecamatans: [],
      searchKecamatan: "",

      selectedKelurahan: null,
      kelurahans: [],
      searchKelurahan: "",

      selectedBank: null,
      banks: [],
      searchBank: "",
      no_rekening: "",
      atas_nama_rekening: "",

      alamat_ktp: "",
      konfirmasipassword: "",
      passwordawal: "",
      imageUrl: "",
      loading: false,
      nama_file: "",
      user_status: "",
      isLoading: false,

      snackbar: false,
      text: "My timeout is set to 2000.",
      timeout: 2000,
      tokenFCM: localStorage.getItem("tokenFCM"),
    };
  },
  created: function () {
    this.GetDataUsers();
  },
  methods: {
    async Upload() {
      if (
        this.nama == "" ||
        this.no_hp == "" ||
        this.nik == "" ||
        this.selectedProvinsi == null ||
        this.selectedKabupaten == null ||
        this.selectedKecamatan == null ||
        this.selectedKelurahan == null ||
        this.selectedBank == null ||
        this.no_rekening == null ||
        this.atas_nama_rekening == null
      ) {
        this.text = "Please Input Required Field";
        this.snackbar = true;
      } else {
        this.isLoading = true; // Mengaktifkan loader
        try {
          let file_upload;
          let formData = new FormData();

          file_upload = this.file;
          console.log("Ktp", file_upload);
          formData.append("file", file_upload);

          const response = await axios.post(
            this.url + "uploadfile.php",
            formData
          );
          this.response = response.data;
          console.warn(response.data);
          this.isLoading = false; // Menonaktifkan loader

          if (response.data == "File already exists.") {
            this.text = "File already exists";
            this.snackbar = true;
          } else if (response.data == "Data sudah tersedia") {
            this.text = "File already exists";
            this.snackbar = true;
          } else if (response.data == "Only Image files are allowed.") {
            this.text = "Only Image files are allowed";
            this.snackbar = true;
          } else if (response.data.status == "success") {
            this.nama_file = response.data.file_name;
            console.info(this.nama_file);
            this.HandleSubmit();
          } else {
            this.text = "Cek Lagi File Yang Di Upload";
            this.snackbar = true;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async HandleClick() {
      this.ShowPassword = !this.ShowPassword;
    },
    async HandleClickKonfirm() {
      this.ShowPassword = !this.ShowPassword;
    },
    async HandleSubmit() {
      if (this.passwordawal !== this.konfirmasipassword) {
        this.text =
          "PIN yang anda masukan tidak sama dengan konfirmasi PIN";
        this.snackbar = true;
      } else {
        this.isLoading = true;
        try {
          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": this.MediaKeys,
          };
          const response = await axios.post(
            this.url + "transaksi/update_m_users",
            {
              nama: this.nama,
              no_hp: this.no_hp,
              nik: this.nik,
              photo_ktp: this.nama_file,
              provinsi_id: this.selectedProvinsi,
              kab_kota_id: this.selectedKabupaten,
              kecamatan_id: this.selectedKecamatan,
              daerah_id: this.selectedKelurahan,
              alamat_ktp: this.alamat_ktp,
              password: this.passwordawal,
              bank: this.selectedBank,
              no_rekening: this.no_rekening,
              atas_nama_rekening: this.atas_nama_rekening,
              tokenFCM: this.tokenFCM,
            },
            { headers }
          );
          this.GetDataUsers();

          console.log(response.data);
          if (response.data.status == "fail") {
            this.text = "No. HP atau Password Salah";
            this.snackbar = true;
          } else {
            //localStorage.setItem("user_status", );
            //this.$router.push("/");
            this.snackbar = true;
            this.text = "Sukses";
          }
          this.isLoading = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async GetDataUsers() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_data_m_users",
          {
            ID: this.ID,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          // swal({
          //   title: "Info",
          //   text: "Data Tidak Tersedia",
          //   icon: "info",
          // });
        } else {
          //this.response = response.data;
          this.nama = response.data.data.nama;
          this.no_hp = response.data.data.no_hp;
          this.nik = response.data.data.nik;
          this.selectedProvinsi = response.data.data.provinsi_id;
          this.selectedKabupaten = response.data.data.kab_kota_id;
          this.selectedKecamatan = response.data.data.kecamatan_id;
          this.selectedKelurahan = response.data.data.daerah_id;
          this.alamat_ktp = response.data.data.alamat_ktp;
          this.selectedBank = response.data.data.bank;
          this.no_rekening = response.data.data.no_rekening;
          this.atas_nama_rekening = response.data.data.atas_nama_rekening;
          if (response.data.data.photo_ktp !== null) {
            this.imageUrl =
              this.url + "uploads/" + response.data.data.photo_ktp;
          }
          this.user_status = response.data.data.user_status;
          this.alamat_pengambilan = response.data.data.alamat;
          localStorage.setItem("user_status", this.user_status);
          localStorage.setItem("provinsi_id", this.selectedProvinsi);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async Reset() {
      (this.email = ""), (this.password = "");
    },
    async Logout() {
      this.$router.push("/logout");
    },
    async login() {
      this.formdaftar = false;
      this.formlogin = true;
    },
    Home() {
      this.$router.push("/");
    },
    Lokasi() {
      this.$router.push("/lokasi");
    },
    onBlur() {
      if (!this.selectedProvinsi) {
        this.searchProvinsi = "";
      }
      if (!this.selectedKabupaten) {
        this.searchKabupaten = "";
      }
      if (!this.selectedKecamatan) {
        this.searchKecamatan = "";
      }
      if (!this.selectedKelurahan) {
        this.searchKelurahan = "";
      }
      if (!this.selectedBank) {
        this.searchBank = "";
      }
    },
    async searchBanks() {
      this.loading = true;
      this.banks = await fetchBankFromDatabase(
        this.searchBank,
        this.url,
        this.MediaKeys
      );
      this.loading = false;
    },
    async searchProvinsis() {
      this.loading = true;
      this.provinsis = await fetchProvinsisFromDatabase(
        this.searchProvinsi,
        this.url,
        this.MediaKeys
      );
      this.loading = false;
    },
    async searchKabupatens() {
      this.loading = true;
      this.kabupatens = await fetchKabupatensFromDatabase(
        this.searchKabupaten,
        this.selectedProvinsi,
        this.url,
        this.MediaKeys
      );
      this.loading = false;
    },
    async searchKecamatans() {
      this.loading = true;
      this.kecamatans = await fetchKecamatansFromDatabase(
        this.searchKecamatan,
        this.selectedProvinsi,
        this.selectedKabupaten,
        this.url,
        this.MediaKeys
      );
      this.loading = false;
    },
    async searchKelurahans() {
      this.loading = true;
      this.kelurahans = await fetchKelurahansFromDatabase(
        this.searchKelurahan,
        this.selectedProvinsi,
        this.selectedKabupaten,
        this.selectedKecamatan,
        this.url,
        this.MediaKeys
      );
      this.loading = false;
    },
  },
  watch: {
    searchProvinsi: function () {
      this.searchProvinsis();
    },
    searchKabupaten: function () {
      this.searchKabupatens();
    },
    searchKecamatan: function () {
      this.searchKecamatans();
    },
    searchKelurahan: function () {
      this.searchKelurahans();
    },
    searchBank: function () {
      this.searchBanks();
    },
  },
};

async function fetchBankFromDatabase(search, url, MediaKeys) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-API-KEY": MediaKeys,
  };
  const response = await axios.get(url + "master/get_bank", {
    params: { search },
    headers: headers,
  });
  //console.warn(params)
  return response.data.data;
}

async function fetchProvinsisFromDatabase(search,url,MediaKeys) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-API-KEY": MediaKeys,
  };
  const response = await axios.get(url + "master/get_provinsi", {
    params: { search },
    headers: headers,
  });
  //console.warn(params)
  return response.data.data;
}

async function fetchKabupatensFromDatabase(search, provinsi_id,url,MediaKeys) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-API-KEY": MediaKeys,
  };
  const response = await axios.get(url + "master/get_kabupaten", {
    params: { search, provinsi_id },
    headers: headers,
  });
  //console.warn(params)
  return response.data.data;
}
async function fetchKecamatansFromDatabase(search, provinsi_id, kab_kota_id,url,MediaKeys) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-API-KEY": MediaKeys,
  };
  const response = await axios.get(url + "master/get_kecamatan", {
    params: { search, provinsi_id, kab_kota_id },
    headers: headers,
  });
  //console.warn(params)
  return response.data.data;
}

async function fetchKelurahansFromDatabase(
  search,
  provinsi_id,
  kab_kota_id,
  kecamatan_id,url,MediaKeys
) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-API-KEY": MediaKeys,
  };
  const response = await axios.get(url + "master/get_kelurahan", {
    params: { search, provinsi_id, kab_kota_id, kecamatan_id },
    headers: headers,
  });
  //console.warn(params)
  return response.data.data;
}
</script>
  
  <style>
.login-view {
  background-image: url("https://www.ptppi.co.id/wp-content/uploads/2022/01/PPI-ID-Food.png");
  background-size: cover;
}
.row_login {
  justify-content: center;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
</style>
<style>
.custom-header-tanpa-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px; /* Adjust the height as needed */
  background-color: #ffffff;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  z-index: 1000;
}

.header-title {
  font-size: 18px;
  font-weight: bold;
}
</style>
  