<template>
  <v-sheet>
    <v-form ref="form">
      <div>
        <div class="custom-header">
          <v-btn @click="Home" icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="header-title">Delivery Plan</div>
        </div>
      </div>
      <br />
      <br />
      <br />

      <LMap
        :zoom="mapOptions.zoom"
        :center="mapOptions.center"
        style="height: 270px; z-index: 0"
      >
        <LTileLayer
          :url="tileLayerOptions.url"
          :attribution="tileLayerOptions.attribution"
        ></LTileLayer>

        <!-- <LCircleMarker :lat-lng="absentLocation" :radius="circleRadius" color="red"></LCircleMarker> -->
        <!-- Use the correct URL for marker icon -->
        <LMarker :lat-lng="currentLocation" :icon="customIcon"></LMarker>
      </LMap>
      <v-card-text>
        <v-row>
          <v-col align="center">
            <v-chip color="red" text-color="white" style="font-size: 12px">{{
              pesan
            }}</v-chip>
            <v-text-field
              prepend-icon="mdi-timetable"
              v-model="jam_ops"
              readonly
              rounded
            />
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              label="Latitude"
              v-model="latitude"
              :rules="required"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              label="Longitude"
              v-model="longitude"
              :rules="required"
              readonly
            ></v-text-field>
          </v-col>
        </v-row> -->
        <v-textarea
          outlined
          label="Detail Alamat Pengantaran"
          v-model="alamat"
          :rules="required"
          readonly
        ></v-textarea>

        <v-menu
          v-model="menu_date_pickup"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              v-model="dateTime_pickup"
              label="Tanggal dan Waktu Pengantaran"
              :format="dateTimeFormat"
              v-on="on"
              :rules="required"
              readonly
              single-line
            ></v-text-field>
          </template>

          <v-time-picker
            v-if="menu_date_pickup"
            v-model="time_pickup"
            full-width
          ></v-time-picker>

          <v-date-picker
            v-model="date_pickup"
            @input="menu_date_pickup = false"
            :min="tomorrow"
            @change="updateDateTime"
          ></v-date-picker>
        </v-menu>

        <v-row>
          <span style="font-size: large; font-style: bold; text-align: left">
            Minimum Jumlah Minyak {{ minAwalLiter }} {{ satuan }}
          </span>
          <v-col cols="4">
            <v-text-field
              outlined
              v-model="awal_liter"
              min="minAwalLiter"
              single-line
              type="number"
              @input="hitungTotal"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              v-model="harga"
              readonly
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
              outlined
              v-model="total"
              readonly
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mx-4 mb-1"></v-divider>
        <div v-if="isLoading" class="loader-overlay"></div>
        <v-snackbar v-model="snackbar" :timeout="timeout" class="snackbar">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      </v-card-text>
    </v-form>
    <div class="custom-footer">
      <v-btn block size="x-large" color="warning" @click="Request">
        Submit
      </v-btn>
    </div>
  </v-sheet>
</template>
  
  <script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet"; // Import the Leaflet library
import axios from "axios";
//import swal from "sweetalert";

export default {
  name: "LoginView",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },

  data() {
    return {
      ShowPassword: false,
      jam_ops: "Jam Operasional 05:00 sd 22:00 WIB",
      nik: "",
      id: "",
      pesan: "",
      awal_liter: 10,
      harga: 0,
      total: 0,
      minAwalLiter: 10,
      satuan: "",
      latitude: 0,
      longitude: 0,
      isLoading: false,
      lokasi_presensi_id: "",
      isabsenmasuk: "",
      circleRadius: 50,
      alamat: "",
      id_user_referal: "",
      nama_referal: "",
      no_hp_referal: "",
      menu_date_pickup: false,
      date_pickup: '',//new Date().toISOString().substr(0, 10),
      time_pickup: '', // Adjust the default time as needed
      dateTime_pickup: null,
      today: new Date().toISOString().substr(0, 10),
      tomorrow: null,
      required: [(v) => !!v || "This field is required"],
      dateTimeFormat: "YYYY-MM-DDTHH:mm:ss",
      provinsi_id: localStorage.getItem("provinsi_id"),
      m_users_id: localStorage.getItem("ID"),
      url: localStorage.getItem("url"),
      MediaKeys: localStorage.getItem("key"),
      no_referal: localStorage.getItem("no_referal"),
      role: localStorage.getItem("role_id"),
      snackbar: false,
      text: "My timeout is set to 2000.",
      timeout: 2000,

      // Define the mapOptions property with default values
      mapOptions: {
        zoom: 18,
        center: [-6.1712807, 106.8190605], // Replace with the desired center coordinates [latitude, longitude]
      },
      // Define the tileLayerOptions property with default values
      tileLayerOptions: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "",
      },
      // Current location data
      currentLocation: [-6.1712807, 106.8190605], // Replace with the initial location [latitude, longitude]
      // Custom icon for marker
      customIcon: L.icon({
        iconUrl: "/assets/images/marker-icon.png", // Replace with the correct URL to your custom marker icon
        iconSize: [25, 41],
        iconAnchor: [16, 32],
      }),
    };
  },

  created: function () {},

  mounted() {
    //this.getLokasi();
    this.GetDataReferal();
    this.GetDataHarga();
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate());

    // Format tanggal sebagai string YYYY-MM-DD
    const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

    // Mengatur nilai tomorrow pada data component
    this.tomorrow = tomorrowFormatted;
    //this.HitungTotal();
    //this.cekAbsen();

    //this.showMap();
  },

  methods: {
    Home() {
      this.$router.push("/");
    },

    // Method for showing the map
    updateDateTime() {
      // Combine date and time when time changes
      this.dateTime_pickup = `${this.date_pickup}T${this.time_pickup}`;
    },
    hitungTotal() {
      // Pastikan bahwa awal_liter, akhir_liter, dan harga berisi angka
      const awalLiter = parseFloat(this.awal_liter);
      const harga = parseFloat(this.harga);
      const min = parseFloat(this.minAwalLiter);

      // Lakukan perhitungan hanya jika nilai-nilai valid
      if (!isNaN(awalLiter) && !isNaN(harga) && awalLiter >= min) {
        const total = awalLiter * harga;
        // Update nilai total
        this.total = total.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
      } else {
        this.minAwalLiter = min;
        this.total = "0"; // Set total menjadi kosong jika ada nilai yang tidak valid
      }
      // console.log("Awal", awalLiter);
      // console.log("harga", harga);
      // console.log("total", this.total);
    },
    async GetDataHarga() {
      this.isLoading = true;
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "master/get_data_harga_komoditi",
          {
            provinsi_id: this.provinsi_id,
            role: this.role,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data);
        if (response.data.status == "fail") {
          this.snackbar = true;
          this.text = "Harga Jual Komdoditi Tidak Tersedia";
          //this.$router.push("/");
        } else {
          //this.response = response.data;
          this.pesan = response.data.data.pesan;
          this.jam_ops = response.data.data.jam_ops;
          this.harga = response.data.data.harga;
          this.minAwalLiter = response.data.data.min_qty;
          this.awal_liter = response.data.data.min_qty;
          this.satuan = response.data.data.satuan;
          const total = this.awal_liter * this.harga;
          // Update nilai total
          this.total = total.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          });
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async GetDataReferal() {
      this.isLoading = true;
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "master/get_data_lokasi",
          {
            id_user: this.no_referal,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data);
        if (response.data.status == "fail") {
          this.snackbar = true;
          this.text = "Alamat Referal Tidak Tersedia";
          //this.$router.push("/");
        } else {
          //this.response = response.data;
          this.id_user_referal = response.data.data.id_user;
          this.nama_referal = response.data.data.nama;
          this.no_hp_referal = response.data.data.no_hp;
          this.alamat = response.data.data.alamat;
          this.latitude = response.data.data.latitude;
          this.longitude = response.data.data.longitude;
          this.showPosition();
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async Request() {
      if (
        this.date_pickup == "" ||
        this.time_pickup == "" ||
        this.alamat == "" ||
        this.latitude == "0" ||
        this.longitude == "0"
      ) {
        this.snackbar = true;
        this.text = "Please Input Required Field !!!";
      } else {
        this.isLoading = true; // Mengaktifkan loader
        try {
          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": this.MediaKeys,
          };
          const response = await axios.post(
            this.url + "transaksi/t_pickup",
            {
              latitude: this.latitude,
              longitude: this.longitude,
              alamat_pickup: this.alamat,
              tgl_pickup: this.date_pickup,
              jam_pickup: this.time_pickup,
              qty_pickup: this.awal_liter,
              harga_pickup: this.harga,
              total_pickup: this.total,
              status_pickup_id: 0,
              m_users_id: this.m_users_id,
              komoditi_id: 2,
              no_referal: this.id_user_referal,
              no_hp_referal: this.no_hp_referal,
              nama_referal: this.nama_referal,
              alamat_referal: this.alamat,
            },
            { headers }
          );
          if (response.data.status === "fail") {
            // swal({
            //   title: "Error",
            //   text: "Anda Sudah Melakukan Request Pickup Hari ini",
            //   icon: "warning",
            // });
            this.snackbar = true;
            this.text = "Konfirmasi Gagal !!!";
            this.isLoading = false; // Menonaktifkan loader
          } else {
            // swal({
            //   title: "Success",
            //   text: "Pickup Success",
            //   icon: "success",
            // });
            this.snackbar = true;
            this.text = "Konfirmasi Sukses, Silahkan cek di menu riwayat";
            this.isLoading = false;
            //this.$router.push("/history");
          }
        } catch (err) {
          console.log(err);
        }
      } //AIzaSyCJh39wgFhAOoLAcXG_svJfZqbq12gkULU
    },
    async getLokasi() {
      if (navigator.geolocation) {
        const options = {
          enableHighAccuracy: true, // Mengaktifkan akurasi tinggi
        };
        navigator.geolocation.watchPosition(
          this.showPosition,
          this.showError,
          options
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },
    showPosition() {
      // this.latitude = position.coords.latitude;
      // this.longitude = position.coords.longitude;
      // Update the map's center and marker position with the new coordinates
      this.mapOptions.center = [this.latitude, this.longitude];
      this.currentLocation = [this.latitude, this.longitude];

      // Lakukan sesuatu dengan latitude dan longitude ini, seperti menyimpannya di data komponen atau mengirimkannya ke server.
      // this.updateAddressFromCoordinates();
    },
    handleMarkerDragEnd(event) {
      // Update the coordinates when the marker is dragged
      this.latitude = event.target.getLatLng().lat;
      this.longitude = event.target.getLatLng().lng;

      // Use reverse geocoding to update the address
      this.updateAddressFromCoordinates();
      // console.log(this.latitude)
      // console.log(this.longitude)
    },
    // New method for updating address from coordinates
    async updateAddressFromCoordinates() {
      try {
        // Use a reverse geocoding service (you may need to sign up for an API key)
        //const apiKey = "AIzaSyCJh39wgFhAOoLAcXG_svJfZqbq12gkULU";
        const response = await axios.get(
          //`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.latitude},${this.longitude}&key=${apiKey}`
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.latitude}&lon=${this.longitude}`
        );

        // Check if the response is successful and contains address components
        if (response.status === 200) {
          const addressComponents = response.data.display_name;
          // Update the address field
          this.alamat = addressComponents;
        } else {
          console.error(
            "Reverse geocoding failed or no address components found."
          );
        }
      } catch (error) {
        console.error("Error during reverse geocoding:", error);
      }
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          console.log("An unknown error occurred.");
          break;
      }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const earthRadius = 6371; // Radius bumi dalam kilometer
      const meterConversion = 1000; // Faktor konversi kilometer ke meter

      // Ubah latitude dan longitude ke radian
      const dLat = this.toRadians(lat2 - lat1);
      const dLon = this.toRadians(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRadians(lat1)) *
          Math.cos(this.toRadians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = earthRadius * c * meterConversion; // Jarak dalam meter

      return distance;
    },
    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    },

    async cekAbsen() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          this.url + "presensi/cek",
          {
            nik: this.nama,
          },
          { headers }
        );
        if (response.data.status === "fail") {
          this.isabsenmasuk = "1";
        } else {
          this.isabsenmasuk = "";
        }
      } catch (err) {
        console.log(err);
      }
    },
    async Masuk() {
      try {
        let d = new Date();
        var uuid = {
          id: d.getTime(),
        };
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          this.url + "presensi/masuk",
          {
            nik: this.nama,
            jam_ops: this.jam_ops,
            device_id: uuid.id,
            latitude: this.latitude,
            longitude: this.longitude,
            lokasi_presensi_id: this.lokasi_presensi_id,
          },
          { headers }
        );
        if (response.data.status === "fail") {
          // swal({
          //   title: "Error",
          //   text: "You Have Checked-In",
          //   icon: "warning",
          // });
          this.isabsenmasuk = "1";
          this.isLoading = false; // Menonaktifkan loader
        } else {
          this.users = response.data.data;
          // swal({
          //   title: "Success",
          //   text: "Check-In Success",
          //   icon: "success",
          // });
          this.isabsenmasuk = "";
          this.isLoading = false;
          //this.cekAbsen();
          //location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async Pulang() {
      try {
        let d = new Date();
        var uuid = {
          id: d.getTime(),
        };
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          this.url + "presensi/pulang",
          {
            nik: this.nama,
            jam_ops: this.jam_ops,
            device_id: uuid.id,
            latitude: this.latitude,
            longitude: this.longitude,
            lokasi_presensi_id: this.lokasi_presensi_id,
          },
          { headers }
        );
        if (response.data.status === "fail") {
          // swal({
          //   title: "Error",
          //   text: "You Have Checked-Out",
          //   icon: "warning",
          // });
          this.isLoading = false; // Menonaktifkan loader
        } else {
          this.users = response.data.data;
          // swal({
          //   title: "Success",
          //   text: "Check-Out Success",
          //   icon: "success",
          // });
          this.isLoading = false; // Menonaktifkan loader
        }
      } catch (err) {
        console.log(err);
      }
    },
    Reset() {
      (this.jam_ops = ""), (this.NIK = "");
    },
    kembali() {
      this.$router.push("/");
    },
    async getDataLokasiAbsen() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          this.url + "presensi/lokasi_presensi",
          {},
          { headers }
        );
        this.response = response.data;
        // console.log("latitude", response.data.data);
        // console.log("longitude", response.data.data);
        if (response.data.status === "fail") {
          //this.$router.push("/customer");
        } else {
          this.locations = response.data.data;
          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
  
  <style>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pesan {
  font-size: 10px !important;
  align-content: center;
}

.custom-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px; /* Adjust the height as needed */
  background-color: #ffffff;
  color: black;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 1000;
}

.custom-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px; /* Adjust the height as needed */
  background-color: #ffffff;
  color: black;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 1;
}

.header-title {
  font-size: 18px;
  font-weight: bold;
}

</style>