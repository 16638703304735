<template>
  <v-sheet>
    <v-form ref="form">
      <div>
        <div class="custom-header"></div>
      </div>
      <br />
      <br />
      <br />
      <v-carousel
        cycle
        height="220"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>
      <v-card-text>
        <v-card-title>Hi, {{ name_users }}</v-card-title>

        <!-- <v-card-text>
          <v-row>
            <v-col
              align="justify"
              style="font-size: 14px; color: black; font-style: bold"
            >
              Selamat datang di aplikasi WOLES (Waste, Oil and Lubricant
              Ecosystem).
            </v-col>
          </v-row>
        </v-card-text> -->

        <v-alert border="top" elevation="1" outlined prominent type="info">
          Total Transaksi
          <br />
          {{ rp }}
        </v-alert>

        <v-card-subtitle>Update Harga Minyak</v-card-subtitle>
        <div class="px-4">
          <v-chip-group>
            <v-chip>{{ harga }} / Liter</v-chip>
            <v-chip> Berlaku sd {{ tgl }}</v-chip>
          </v-chip-group>
        </div>
        <v-divider class="mx-4 mb-1"></v-divider>
        <div v-if="menu_user">
          <div v-if="user_status === '1' && role === '1'">
            <v-row>
              <v-col cols="4 text-center">
                <v-btn
                  class="mx-2"
                  elevation="1"
                  fab
                  icon
                  large
                  color="warning"
                  @click="Request"
                >
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
                Request
              </v-col>
              <v-col cols="4 text-center">
                <v-badge
                  :content="messages"
                  :value="messages"
                  color="green"
                  overlap
                >
                  <v-btn
                    class="mx-2"
                    elevation="1"
                    fab
                    icon
                    large
                    color="info"
                    @click="ListDelivery"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                </v-badge>
                Delivery
              </v-col>
              <v-col cols="4 text-center">
                <v-badge
                  :content="messages_downline"
                  :value="messages_downline"
                  color="green"
                  overlap
                >
                  <v-btn
                    class="mx-2"
                    elevation="1"
                    fab
                    icon
                    large
                    color="success"
                    @click="Downline"
                  >
                    <v-icon>mdi-account-plus</v-icon>
                  </v-btn>
                </v-badge>
                Downline
              </v-col>
            </v-row>
          </div>
          <div v-if="user_status === '1' && role === '6'">
            <v-row>
              <v-col cols="4 text-center">
                <v-btn
                  class="mx-2"
                  elevation="1"
                  fab
                  icon
                  large
                  color="info"
                  @click="HubKami"
                >
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
                Hub.Kami
              </v-col>
              <v-col cols="4 text-center">
                <v-badge
                  :content="messages"
                  :value="messages"
                  color="green"
                  overlap
                >
                  <v-btn
                    class="mx-2"
                    elevation="1"
                    fab
                    icon
                    large
                    color="warning"
                    @click="Delivery"
                  >
                    <v-icon>mdi-cart-outline</v-icon>
                  </v-btn>
                </v-badge>
                Delivery
              </v-col>
              <v-col cols="4 text-center">
                <v-badge
                  :content="messages_history"
                  :value="messages_history"
                  color="green"
                  overlap
                >
                  <v-btn
                    class="mx-2"
                    elevation="1"
                    fab
                    icon
                    large
                    color="teal"
                    @click="History"
                  >
                    <v-icon>mdi-history</v-icon>
                  </v-btn>
                </v-badge>
                History
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4 text-center">
                <v-btn
                  class="mx-2"
                  elevation="1"
                  fab
                  icon
                  large
                  color="cyan"
                  @click="Profile"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
                Profile
              </v-col>

              <v-col cols="4 text-center">
                <v-btn
                  class="mx-2"
                  elevation="1"
                  fab
                  icon
                  large
                  color="purple"
                  @click="Logout"
                >
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
                Exit
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="4 text-center">
                <v-badge
                  :content="messages_history"
                  :value="messages_history"
                  color="green"
                  overlap
                >
                  <v-btn
                    class="mx-2"
                    elevation="1"
                    fab
                    icon
                    large
                    color="teal"
                    @click="History"
                  >
                    <v-icon>mdi-history</v-icon>
                  </v-btn>
                </v-badge>
                History
              </v-col>
              <v-col cols="4 text-center">
                <v-btn
                  class="mx-2"
                  elevation="1"
                  fab
                  icon
                  large
                  color="cyan"
                  @click="Profile"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
                Profile
              </v-col>

              <v-col cols="4 text-center">
                <v-btn
                  class="mx-2"
                  elevation="1"
                  fab
                  icon
                  large
                  color="purple"
                  @click="Logout"
                >
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
                Exit
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <br />
      <br />
    </v-form>
  </v-sheet>
</template>

<script>
import axios from "axios";
// import swal from "sweetalert";
export default {
  components: {},
  data: () => ({
    items: [],
    user_status: localStorage.getItem("user_status"),
    provinsi_id: localStorage.getItem("provinsi_id"),
    role: localStorage.getItem("role_id"),
    url: localStorage.getItem("url"),
    MediaKeys: localStorage.getItem("key"),
    harga: 0,
    tgl: "",
    rp: 0,
    menu_user: false,
    menu_admin: false,
    menu_driver: false,
    messages: 0,
    messages_downline: 0,
    messages_history: 0,
  }),
  created: function () {
    const name_users = localStorage.getItem("name_users");
    const ID = localStorage.getItem("ID");
    this.ID = ID;
    this.name_users = name_users;
    this.GetCarousels();
    this.GetDataRp();
    this.GetDataHarga();
    this.GetBadgesDelivery();
    this.GetBadgesDownline();
    this.GetBadgesHistory();
    const role_id = localStorage.getItem("role_id");
    if (role_id == "1" || role_id == "6") {
      this.menu_user = true;
      this.menu_admin = false;
      this.menu_driver = false;
    } else if (role_id == "2") {
      this.menu_user = false;
      this.menu_admin = true;
      this.menu_driver = false;
    } else if (role_id == "3") {
      this.menu_user = false;
      this.menu_admin = false;
      this.menu_driver = true;
    }
  },

  methods: {
    async HandleLogout() {
      try {
        localStorage.setItem("authenticated", false);
        localStorage.setItem("ID", "");
        localStorage.setItem("no_hp", "");
        localStorage.setItem("role_id", "");
        localStorage.setItem("name_users", "");
        localStorage.setItem("user_status", "");
        localStorage.setItem("provinsi_id", "");
        localStorage.setItem("key", "");
        localStorage.setItem("url", "");
        localStorage.setItem("no_referal", "");
        localStorage.setItem("otp", "");
        localStorage.setItem("tokenFCM", "");
        // this.$router.push("/");
        location.reload();
      } catch (err) {
        console.log(err);
      }
    },
    Request() {
      this.$router.push("/request");
    },
    Delivery() {
      this.$router.push("/delivery");
    },
    ListDelivery() {
      this.$router.push("/list");
    },
    Profile() {
      this.$router.push("/profile");
    },
    HubKami() {
      window.open(
        "https://api.whatsapp.com/send?phone=62213505351&text=Hai%20Admin%20Saya%20mau%20tanya%20tentang%20Aplikasi WOLES%2C%20Saya%20dapat%20wa%20nya%20dari%20Aplikasi WOLES%20nya%20Terima%20kasih",
        "_blank"
      );
    },
    LinkPPI() {
      window.open("https://www.ptppi.co.id/", "_blank");
    },
    Downline() {
      this.$router.push("/downline");
    },
    History() {
      this.$router.push("/history");
    },
    Logout() {
      //this.$router.push("/logout");
      this.HandleLogout();
    },
    Inbox() {
      this.$router.push("/inbox");
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    async GetBadgesDelivery() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_countDelivery",
          { id_user: this.ID },
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          this.messages = 0;
        } else {
          if (response.data.data.id == 0) {
            this.messages = 0;
          } else {
            this.messages = response.data.data.id;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async GetBadgesDownline() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_countDownline",
          { id_user: this.ID },
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          this.messages_downline = 0;
        } else {
          if (response.data.data.id == 0) {
            this.messages_downline = 0;
          } else {
            this.messages_downline = response.data.data.id;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async GetBadgesHistory() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_countHistory",
          { id_user: this.ID },
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          this.messages_history = 0;
        } else {
          if (response.data.data.id == 0) {
            this.messages_history = 0;
          } else {
            this.messages_history = response.data.data.id;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async GetCarousels() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "master/get_carousels",
          {},
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          this.items = [];
        } else {
          this.items = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async GetDataHarga() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "master/get_data_harga_komoditi",
          {
            provinsi_id: this.provinsi_id,
            role: this.role,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          // swal({
          //   title: "Info",
          //   text: "Harga Tidak Tersedia",
          //   icon: "info",
          // });
          //this.$router.push("/");
          this.harga = "Harga Tidak Tersedia";
          this.tgl = "";
        } else {
          //this.response = response.data;
          const harga = parseInt(response.data.data.harga);
          this.tgl = response.data.data.end;

          console.log("Harga", harga);
          this.harga = harga.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async GetDataRp() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_rp_user",
          {
            id_user: this.ID,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          this.rp = 0;
        } else {
          //this.response = response.data;
          const total_pickup = parseFloat(response.data.data.total_pickup);
          if (total_pickup >= 0) {
            console.log("Rp", response.data.data.total_pickup);
            this.rp = total_pickup.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 0,
            });
          } else {
            this.rp = "Rp.0";
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style>
.mynik {
  font-size: 13px !important;
}
.mytitle {
  font-size: 24px !important;
}
</style>