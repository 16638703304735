<template>
  <v-sheet>
    <v-form ref="form">
      <v-card-text>
        <div>
          <div class="custom-header-tanpa-back">
            <div class="header-title">List Delivery</div>
          </div>
        </div>
        <br />
        <br />
        <br />

        <v-autocomplete
          v-model="selectedItem"
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          :no-filter="true"
          item-text="status_pickup"
          item-value="id"
          @blur="onBlur"
          label="Status"
          clearable
          outlined
        ></v-autocomplete>

        <div v-if="isLoading" class="loader-overlay"></div>
        <div class="d-flex flex-column">
          <v-btn color="warning" @click="getDataTable">
            <v-icon left>mdi-filter</v-icon>Filter
          </v-btn>
        </div>
        <v-divider class="mx-4 mb-1"></v-divider>
        <div v-for="item in items_data" :key="item.pickup_id">
          <v-row dense>
            <v-col cols="12">
              <v-card>
                <v-card-title class="no-padding"
                  >#{{ item.pickup_id }} {{ item.nama_komoditi }}
                  <v-divider></v-divider
                  ><v-chip-group>
                    <v-chip
                      color="red"
                      text-color="white"
                      @click="showEdit(item)"
                      >{{ item.status_pickup }}</v-chip
                    >
                  </v-chip-group></v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" align="left" class="no-padding">{{
                      item.tgl_pickup
                    }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left" class="no-padding">{{
                      item.nama
                    }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      align="left"
                      class="no-padding"
                      style="font-size: 12px"
                      ><a
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send?phone=' + item.no_hp
                        "
                        >{{ item.no_hp }}</a
                      ></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      align="left"
                      class="no-padding"
                      style="font-size: 12px"
                      ><a
                        target="_blank"
                        :href="
                          'https://maps.google.com?daddr=' +
                          item.latitude +
                          ',' +
                          item.longitude
                        "
                        >{{ item.alamat_pickup }}</a
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <br />
        <br />
        <br />
        <div v-if="data">
          <img
            :src="not_found"
            alt="Photo"
            :style="{ width: '300px', height: 'auto' }"
          />
        </div>
      </v-card-text>
      <v-row justify="center">
        <v-dialog v-model="dialogEdit" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Driver Ditugaskan</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      label="ID"
                      v-model="editpickup_id"
                      readonly
                    ></v-text-field>

                    <v-autocomplete
                      v-model="selectedStatus"
                      :items="statuss"
                      :loading="loading"
                      :search-input.sync="searchstatus"
                      :no-filter="true"
                      item-text="status_pickup"
                      item-value="id"
                      @blur="onBlur"
                      label="Status"
                      clearable
                      outlined
                    ></v-autocomplete>

                    <div v-if="selectedStatus === '4'">
                      <v-text-field
                        outlined
                        label="Plat No. Kendaraan"
                        v-model="plat_no"
                        single-line
                        hint="Contoh, B1121N"
                        persistent-hint
                      ></v-text-field>
                    </div>

                    <div v-else-if="selectedStatus === '5'">
                      <v-text-field
                        outlined
                        label="Qty"
                        v-model="qty_pickup"
                        min="minAwalLiter"
                        type="number"
                        @input="hitungTotal"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        label="Harga"
                        v-model="harga_pickup"
                        readonly
                      >
                      </v-text-field>
                      <v-text-field
                        outlined
                        label="Total"
                        v-model="total_pickup"
                        readonly
                      >
                      </v-text-field>
                      <div v-if="imageUrl">
                        <a :href="imageUrl" target="_blank">
                          <img
                            :src="imageUrl"
                            alt="Photo"
                            :style="{ width: '300px', height: 'auto' }"
                          />
                        </a>
                      </div>
                      <div v-else>
                        <p>No image available</p>
                      </div>
                      <br />
                      <br />
                      <v-file-input
                        v-model="file"
                        show-size
                        counter
                        accept="image/*"
                        label="Ambil Photo"
                        outlined
                        capture
                      ></v-file-input>
                    </div>

                    <div v-else-if="selectedStatus === '6'">
                      <v-text-field
                        outlined
                        label="Qty"
                        v-model="qty_pickup"
                        min="minAwalLiter"
                        type="number"
                        @input="hitungTotal"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        label="Harga"
                        v-model="harga_pickup"
                        readonly
                      >
                      </v-text-field>
                      <v-text-field
                        outlined
                        label="Total"
                        v-model="total_pickup"
                        readonly
                      >
                      </v-text-field>
                      <v-textarea
                        outlined
                        label="Keterangan Perubahan"
                        v-model="keterangan_perubahan"
                      ></v-textarea>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogEdit = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="Upload">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
    <br />
    <br />
  </v-sheet>
</template>
  
  <script>
import axios from "axios";
import swal from "sweetalert";
export default {
  components: {},
  data() {
    return {
      selectedItem: null,
      items: [],
      search: "",
      loading: false,

      selectedStatus: null,
      statuss: [],
      searchstatus: "",

      isLoading: false,

      dialogEdit: false,
      editpickup_id: "",
      plat_no: "",
      file: null,
      photo: "",
      imageUrl: "",

      qty_pickup: 0,
      harga_pickup: 0,
      total_pickup: 0,
      keterangan_perubahan: "",

      items_data: [],
      data: false,
      id_karyawan: localStorage.getItem("ID"),
      role_id: localStorage.getItem("role_id"),
      url: localStorage.getItem("url"),
      MediaKeys: localStorage.getItem("key"),
      not_found:
        "https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg?size=626&ext=jpg&ga=GA1.1.1219781391.1697861332&semt=ais",
    };
  },
  created: function () {
    this.getDataTable();
  },
  mounted() {},

  methods: {
    onBlur() {
      if (!this.selectedItem) {
        this.search = "";
      }
      if (!this.selectedStatus) {
        this.searchstatus = "";
      }
    },
    async searchItems() {
      this.loading = true;
      this.items = await this.fetchStatusFromDatabase(this.search);
      this.loading = false;
    },
    async searchStatus() {
      this.loading = true;
      this.statuss = await this.fetchStatusFromDatabase(this.searchstatus);
      this.loading = false;
    },

    async getDataTable() {
      this.isLoading = true; // Mengaktifkan loader
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_history",
          {
            status_id: this.selectedItem,
            m_users_id: this.id_karyawan,
            role_id: this.role_id,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data.status);
        if (response.data.status === "fail") {
          //this.$router.push("/customer");
          this.items_data = [];
          this.data = true;
        } else {
          this.data = false;
          this.items_data = response.data.data;

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async showEdit(user) {
      this.dialogEdit = true;
      this.editpickup_id = user.pickup_id;
      this.editnama_mitra = user.nama;
      this.qty_pickup = user.qty_pickup;
      this.harga_pickup = user.harga_pickup;
      this.total_pickup = user.total_pickup.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
      this.keterangan_perubahan = user.keterangan_perubahan;
      if (user.photo !== null) {
        this.imageUrl = this.url + "uploads/" + user.photo;
        console.log(user);
      }
    },
    hitungTotal() {
      // Pastikan bahwa awal_liter, akhir_liter, dan harga berisi angka
      const awalLiter = parseFloat(this.qty_pickup);
      const harga = parseFloat(this.harga_pickup);

      // Lakukan perhitungan hanya jika nilai-nilai valid
      if (!isNaN(awalLiter) && !isNaN(harga)) {
        const total = awalLiter * harga;
        // Update nilai total
        this.total_pickup = total.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
      } else {
        this.total_pickup = "0"; // Set total menjadi kosong jika ada nilai yang tidak valid
      }
      console.log("Awal", awalLiter);
      console.log("harga", harga);
      console.log("total", this.total_pickup);
    },
    async Upload() {
      if (this.selectedStatus == null) {
        swal("Please Input Required Field");
      } else {
        this.isLoading = true; // Mengaktifkan loader
        try {
          let formData = new FormData();
          formData.append("file", this.file);

          const response = await axios.post(
            this.url + "uploadfile.php",
            formData
          );
          this.response = response.data;
          console.warn(response.data);
          this.isLoading = false; // Menonaktifkan loader

          if (response.data == "File already exists.") {
            swal({
              title: "Error",
              text: "File already exists",
              icon: "warning",
            });
          } else if (response.data == "Data sudah tersedia") {
            swal({
              title: "Error",
              text: "Data already exists",
              icon: "warning",
            });
          } else if (response.data == "Only Image files are allowed.") {
            swal({
              title: "Error",
              text: "Only Image files are allowed",
              icon: "warning",
            });
          } else if (response.data.status == "success") {
            swal({
              title: "Success",
              text: "Save Success",
              icon: "success",
            });
            this.photo = response.data.file_name;
            console.info(this.photo);
            this.HandleDriver();
          } else {
            swal({
              title: "Error",
              text: "Cek Lagi File Yang Di Upload",
              icon: "warning",
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async HandleDriver() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/driver_dalam_perjalan",

          {
            pickup_id: this.editpickup_id,
            plat_no: this.plat_no,
            photo: this.photo,
            status_pickup_id: this.selectedStatus,
            qty_pickup: this.qty_pickup,
            harga_pickup: this.harga_pickup,
            total_pickup: this.total_pickup,
            keterangan_perubahan: this.keterangan_perubahan,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Approve Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Approve Berhasil",
            icon: "success",
          });
          this.dialogEdit = false;
          this.getDataTable();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchStatusFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_status_driver", {
        params: { search },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },
  },
  watch: {
    search: function () {
      this.searchItems();
    },
    searchstatus: function () {
      this.searchStatus();
    },
  },
};
</script>
  
  <style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
             
<style scoped>
.no-padding {
  padding: 0; /* or padding: 0 !important; if needed */
}
</style>
  
  