<template>
  <v-sheet>
    <v-form ref="form">
      <v-card-text>
        <div v-if="isLoading" class="loader-overlay"></div>
        <div>
            <v-row>
              <v-col cols="2">
                <v-btn @click="login" icon x-large>
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="10" align="left"> </v-col>
            </v-row>
          </div>
          <v-row>
              <v-col align="center" style="font-size: 20px; color: black; font-style: bold;">
               Masukan Kode OTP
                </v-col>
          </v-row>
          <v-row>
              <v-col align="center" style="font-size: 14px; color: black; font-style: bold;">
                Kode OTP dikirim melalui Whatsapp ke {{ no_hp }}
                </v-col>
          </v-row>
          
        <div class="ma-auto position-relative" style="max-width: 300px">
          <v-otp-input
            v-model="otp"
            :disabled="loading"
            @finish="onFinish"
          ></v-otp-input>
          <v-overlay absolute :value="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-overlay>
        </div>
        
        <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000">
          {{ text }}
        </v-snackbar>

        <div>
            <v-row>
              <v-col align="center" style="font-size: 20px; color: red; font-style: bold;">
                {{ remainingTime }}
              </v-col>
             
            </v-row>
          </div>

        <v-divider class="mx-4 mb-1"></v-divider>
        <div v-if="kirim">
          <div class="d-flex flex-column">
            
            <v-btn block size="x-large" color="warning" @click="sendWhatsAppMessage">
              Kirim Ulang OTP
            </v-btn>
          </div>
        </div>

        <div v-else>
          <div class="d-flex flex-column">
            
            <v-btn block size="x-large">
              Tunggu 5 menit untuk mengirim ulang
            </v-btn>
          </div>
        </div>
        
        
        <v-divider class="mx-4 mb-1"></v-divider>
      </v-card-text>
    </v-form>
  </v-sheet>
</template>
  
  <script>
import axios from "axios";
// import swal from "sweetalert";
export default {
  components: {},
  data: () => ({
    user_status: localStorage.getItem("user_status"),
    provinsi_id: localStorage.getItem("provinsi_id"),
    isLoading: false,
    loading: false,
    snackbar: false,
    kirim:false,
    snackbarColor: "default",
    otp: "",
    text: "",

    countdownTime: 300, // Waktu dalam detik (misalnya, 5 menit)
    remainingTime: 0,
    countdownInterval: null
    
  }),
  created: function () {
    const name_users = localStorage.getItem("name_users");
    const ID = localStorage.getItem("ID");
    const no_hp = localStorage.getItem("no_hp");
    const get_otp = localStorage.getItem("otp");
    this.ID = ID;
    this.name_users = name_users;
    this.no_hp = no_hp;
    this.get_otp = get_otp;
    
  },
  mounted() {
    // Inisialisasi waktu tersisa saat komponen dimuat
    this.startCountdown();
    this.remainingTime = this.countdownTime;
  },

  methods: {

    startCountdown() {
      // Mulai countdown dan atur interval untuk mengurangkan waktu
      this.countdownInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          // Countdown selesai, hentikan interval
          clearInterval(this.countdownInterval);
          this.kirim = true;
          //alert('Countdown selesai!');
        }
      }, 1000); // Interval setiap 1 detik
    },
    stopCountdown() {
      // Hentikan countdown
      clearInterval(this.countdownInterval);
    },
  beforeDestroy() {
    // Pastikan untuk membersihkan interval sebelum komponen dihancurkan
    this.stopCountdown();
  },
  async HandleLogout() {
      try {
        //localStorage.setItem("authenticated",false)
        localStorage.setItem("role_id", "");
        this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
    login() {
      this.$router.push("/login");
    },
    
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    
    async HandleOTP() {
      if (this.otp == "") {
        
        this.text="Please Input Required Field";
        this.snackbar = true;
      } else {
        try {
          let authenticated;
          let url = "https://woles.ptppi.co.id/api/";
          let key =
          "$2y$07$s8J1IQvUEVmn0jAE0yZncuLZs18wbEYikZAcwMSxwL9ZoE5coYFJu";

          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": key,
          };
          const response = await axios.post(
            url + "login/cek_otp",
            {
              no_hp: this.no_hp,
              otp: this.otp,
            },
            { headers }
          );
          this.response = response.data;
          console.log(response.data);
          if (response.data.status == "fail") {
            
            this.text="OTP Yang anda masukan salah";
            this.snackbar = true;
            authenticated = false;
          } else {
            authenticated = true;
            localStorage.setItem("authenticated", authenticated);
            localStorage.setItem("ID", response.data.data.id_user);
            localStorage.setItem("no_hp", response.data.data.no_hp);
            localStorage.setItem("role_id", response.data.data.role_id);
            localStorage.setItem("name_users", response.data.data.nama);
            localStorage.setItem("user_status", response.data.data.user_status);
            localStorage.setItem("provinsi_id", response.data.data.provinsi_id);
            localStorage.setItem("url", url);
            localStorage.setItem("key", key);

            //localStorage.setItem("ip_static",response.data.data.ip_static);

            this.$router.push("/");
            location.reload();
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async sendWhatsAppMessage() {
      if (this.no_hp == "") {
        this.text="Masukan No. Handphone Terlebih Dahulu";
        this.snackbar = true;
      } else {
        this.isLoading = true;
        try {
          
          let url = "https://woles.ptppi.co.id/api/";
          let key =
          "$2y$07$s8J1IQvUEVmn0jAE0yZncuLZs18wbEYikZAcwMSxwL9ZoE5coYFJu";

          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": key,
          };
          const response = await axios.post(
            url + "transaksi/generate_otp",
            {
              nohp: this.no_hp

            },
            { headers }
          );
          this.response = response.data;
          //console.log(response.data);
          if (response.data.status == "fail") {
            
            this.text="No. HP Tidak Terdaftar";
            this.snackbar = true;
            
          } else {
            this.isLoading = false;
            
          }
          this.isLoading = false;
          
          this.startCountdown();
          this.remainingTime = this.countdownTime;
          this.kirim = false;
          
        } catch (err) {
          console.log(err);
        }
      }
    },
    onFinish(rsp) {
      this.HandleOTP();  
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.snackbarColor = rsp === this.expectedOtp ? "success" : "warning";
        this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`;
        this.snackbar = true;
      }, 3500);
    },
  },
};
</script>
  <style scoped>

.position-relative {
   position: relative;
 }
</style>