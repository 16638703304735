import { render, staticRenderFns } from "./HistoryView.vue?vue&type=template&id=ad87b544&scoped=true"
import script from "./HistoryView.vue?vue&type=script&lang=js"
export * from "./HistoryView.vue?vue&type=script&lang=js"
import style0 from "./HistoryView.vue?vue&type=style&index=0&id=ad87b544&prod&scoped=true&lang=css"
import style1 from "./HistoryView.vue?vue&type=style&index=1&id=ad87b544&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad87b544",
  null
  
)

export default component.exports