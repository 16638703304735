<template>
  <v-card title elevation="1" class="mt-5;mr-10">
    <v-card-title>
      <h2>List Permintaan Pengambilan</h2>
    </v-card-title>

    <v-form>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="selectedProvinsi"
              :items="provinsis"
              :loading="loading"
              :search-input.sync="searchProvinsi"
              :no-filter="true"
              item-text="provinsi"
              item-value="provinsi_id"
              @blur="onBlur"
              label="Provinsi"
              outlined
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="selectedItem"
              :items="items"
              :loading="loading"
              :search-input.sync="search"
              :no-filter="true"
              item-text="status_pickup"
              item-value="id"
              @blur="onBlur"
              label="Status"
              clearable
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="2">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  v-model="tgl_awal"
                  label="Tgl. Awal Transaksi"
                  :format="dateFormat"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="tgl_awal"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="2">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  v-model="tgl_akhir"
                  label="Tgl. Akhir Transaksi"
                  :format="dateFormat"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="tgl_akhir"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn color="warning" @click="getDataTable">
              <v-icon left>mdi-filter</v-icon>Filter
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>

    <div v-if="isLoading" class="loader-overlay"></div>

    <v-card-text>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="search_table"
          label="Lokasi"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-data-table
        :headers="headers"
        :items="items_table"
        item-key="nama_lokasi"
        :search="search_table"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.pickup_id }}</td>
            <td>{{ row.item.provinsi }}</td>
            <td>{{ row.item.status_pickup }}</td>
            <td>{{ row.item.nama }}</td>
            <td>{{ row.item.no_hp }}</td>
            <td>{{ row.item.alamat_pickup }}</td>
            <td>{{ row.item.nama_komoditi }}</td>
            <td>{{ Number(row.item.qty_pickup).toLocaleString("id-ID") }}</td>
            <td>{{ new Date(row.item.tgl_pickup).toLocaleString("id-ID") }}</td>

            <td>
              <div v-if="row.item.status_pickup_id === '1'">
                <v-btn
                  color="primary"
                  rounded
                  depressed
                  small
                  @click="showApprove(row.item)"
                >
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  rounded
                  depressed
                  small
                  @click="showReject(row.item)"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </div>

              <div v-else-if="row.item.status_pickup_id === '2'">
                <v-btn
                  color="primary"
                  rounded
                  depressed
                  small
                  @click="showDriver(row.item)"
                >
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn>
              </div>

              <div
                v-else-if="
                  row.item.status_pickup_id === '5' ||
                  row.item.status_pickup_id === '6'
                "
              >
                <v-btn
                  color="primary"
                  rounded
                  depressed
                  small
                  @click="showPembayaran(row.item)"
                >
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-row class="text-center px-4 align-center" wrap>
      <v-col class="text-truncate" cols="12" md="2"
        >Total {{ totalRecords }} records</v-col
      >
      <v-col cols="12" md="6">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </v-col>
      <v-col cols="6" md="3">
        <v-select
          dense
          outlined
          hide-details
          :value="itemsPerPage"
          label="Show entries"
          @change="itemsPerPage = parseInt($event, 10)"
          :items="perPageChoices"
        ></v-select>
      </v-col>
      <v-col cols="6" md="1">
        <v-text-field
          v-model="page"
          label="Page"
          type="number"
          outlined
          hide-details
          dense
          @input="page = parseInt($event, 10)"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Edit   -->

    <v-row justify="center">
      <v-dialog v-model="dialogEdit" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Driver Ditugaskan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="ID"
                    v-model="editpickup_id"
                    readonly
                    single-line
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Nama"
                    v-model="editnama_mitra"
                    readonly
                    single-line
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="selectedEditDriver"
                    :items="drivers"
                    :loading="loading"
                    :search-input.sync="searcheditdriver"
                    :no-filter="true"
                    item-text="nama"
                    item-value="id_user"
                    @blur="onBlur"
                    label="Driver"
                    clearable
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogEdit = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="HandleDriver"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Hapus   -->

    <v-row justify="center">
      <v-dialog v-model="dialogReject" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Reject Pickup</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="Keterangan"
                    v-model="keterangan_reject"
                    :rules="required"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogReject = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="HandleReject"
              >Reject</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- //Confirm -->
    <v-row justify="center">
      <v-dialog v-model="dialogApprove" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Approve Pickup</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row> Approve ? </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogApprove = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="HandleApprove"
              >Approve</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- //Confirm -->
    <v-row justify="center">
      <v-dialog v-model="dialogPembayaran" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Pembayaran</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="ID"
                    v-model="edit_pembayaran_pickup_id"
                    readonly
                    single-line
                  ></v-text-field>

                  <v-text-field
                    outlined
                    label="Bank"
                    v-model="bank"
                    readonly
                    single-line
                  ></v-text-field>

                  <v-text-field
                    outlined
                    label="No. Rekening"
                    v-model="no_rekening"
                    readonly
                    single-line
                  ></v-text-field>

                  <v-text-field
                    outlined
                    label="Atas Nama Rekening"
                    v-model="atas_nama_rekening"
                    readonly
                    single-line
                  ></v-text-field>

                  <div v-if="imageUrl">
                    <a :href="imageUrl" target="_blank">
                      <img
                        :src="imageUrl"
                        alt="Photo"
                        :style="{ width: '300px', height: 'auto' }"
                      />
                    </a>
                  </div>
                  <div v-else>
                    <p>No image available</p>
                  </div>
                  <br />
                  <br />
                  <v-file-input
                    v-model="file"
                    show-size
                    counter
                    accept="image/*"
                    label="Upload Bukti Pembayaran"
                    outlined
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogPembayaran = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="Upload">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <br>
    <br>
  </v-card>
</template>



<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  name: "LisHarga",

  data() {
    return {
      selectedItem: null,
      items: [],
      loading: false,
      search: "",
      menu2: "",
      menu1: "",

      selectedProvinsi: null,
      provinsis: [],
      searchProvinsi: "",

      selectedEditDriver: null,
      searcheditdriver: "",
      drivers: [],

      tgl_awal: null,
      tgl_akhir: null,
      dateFormat: "yyyy-mm-dd",

      edit_pembayaran_pickup_id: "",

      numberRule: [
        (v) => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999999) return true;
          return "Number has to be between 0 and 99999";
        },
      ],

      required: [(v) => !!v || "required"],

      users: "",

      editTglAwal: "",
      editHargaItem: "",
      editpickup_id: "",
      editItem: "",
      editIDLokasi: "",
      editnama_mitra: "",
      detailEdit: {},
      dialogEdit: false,

      rejectPickupID: "",
      dialogReject: false,
      keterangan_reject: "",

      dialogApprove: false,
      approvePickupID: "",

      dialogPembayaran: false,
      bank: "",
      no_rekening: "",
      atas_nama_rekening: "",

      file: null,
      bukti_pembayaran: "",
      imageUrl: "",

      isLoading: false,

      // Setting Datatable //
      search_table: "",
      items_table: [],
      headers: [
        { text: "ID", align: "center", filterable: true, value: "pickup_id" },
        { text: "Provinsi", align: "center", filterable: true, value: "provinsi" },
        {
          text: "Status",
          align: "center",
          filterable: true,
          value: "status_pickup",
        },
        {
          text: "Nama",
          align: "center",
          filterable: true,
          value: "nama",
        },
        { text: "No. HP", align: "center", filterable: true, value: "no_hp" },
        {
          text: "Alamat",
          align: "center",
          filterable: true,
          value: "alamat_pickup",
        },
        {
          text: "Komoditi",
          align: "center",
          filterable: true,
          value: "nama_komoditi",
        },
        {
          text: "Qty",
          align: "center",
          filterable: true,
          value: "qty_pickup",
        },
        {
          text: "Tgl. Pickup",
          align: "center",
          filterable: true,
          value: "tgl_pickup",
        },
        { text: "", sortable: true },
      ],
      page: 1,
      itemsPerPage: 10,
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "10 records/page", value: 10 },
        { text: "20 records/page", value: 20 },
      ],
      // Setting Datatable //
      url: localStorage.getItem("url"),
      MediaKeys: localStorage.getItem("key"),
      role_id: localStorage.getItem("role_id"),
      
    };
  },
  created: function () {
    //this.getDataTable();
  },

  methods: {
    async Upload() {
      if (this.file == null) {
        swal("Please Input Required Field");
      } else {
        this.isLoading = true; // Mengaktifkan loader
        try {
          let formData = new FormData();
          formData.append("file", this.file);

          const response = await axios.post(
            this.url + "uploadfile.php",
            formData
          );
          this.response = response.data;
          console.warn(response.data);
          this.isLoading = false; // Menonaktifkan loader

          if (response.data == "File already exists.") {
            swal({
              title: "Error",
              text: "File already exists",
              icon: "warning",
            });
          } else if (response.data == "Data sudah tersedia") {
            swal({
              title: "Error",
              text: "Data already exists",
              icon: "warning",
            });
          } else if (response.data == "Only Image files are allowed.") {
            swal({
              title: "Error",
              text: "Only Image files are allowed",
              icon: "warning",
            });
          } else if (response.data.status == "success") {
            swal({
              title: "Success",
              text: "Save Success",
              icon: "success",
            });
            this.bukti_pembayaran = response.data.file_name;
            console.info(this.bukti_pembayaran);
            this.HandlePembayaran();
          } else {
            swal({
              title: "Error",
              text: "Cek Lagi File Yang Di Upload",
              icon: "warning",
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async getDataTable() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_t_pickup",
          {
            tgl_awal: this.tgl_awal,
            tgl_akhir: this.tgl_akhir,
            id: this.selectedItem,
            provinsi_id: this.selectedProvinsi,
            role_id : this.role_id,
          },
          { headers }
        );
        this.response = response.data;
        //console.log(response.data.status);
        if (response.data.status === "fail") {
          // swal({
          //   title: "Error",
          //   text: "Data Tidak Tersedia",
          //   icon: "warning",
          // });
          this.items_table = [];
        } else {
          this.items_table = response.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async showDriver(user) {
      this.dialogEdit = true;
      this.editHargaItem = user.tarif;
      this.editTglAwal = user.start_at;
      this.editpickup_id = user.pickup_id;
      this.editItem = user.nama_item;
      this.editIDLokasi = user.IDLokasi;
      this.editnama_mitra = user.nama;
      console.warn(user);
    },
    async HandleDriver() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/driver_pickup",

          {
            pickup_id: this.editpickup_id,
            m_driver_id: this.selectedEditDriver,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Approve Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Approve Berhasil",
            icon: "success",
          });
          this.dialogEdit = false;
          this.getDataTable();
        }
      } catch (err) {
        console.log(err);
      }
    },
    showPembayaran(user) {
      this.dialogPembayaran = true;
      this.edit_pembayaran_pickup_id = user.pickup_id;
      this.bank = user.bank;
      this.no_rekening = user.no_rekening;
      this.atas_nama_rekening = user.atas_nama_rekening;
      if (user.bukti_pembayaran != null) {
        this.imageUrl = this.url + "uploads/" + user.bukti_pembayaran;
      }
    },
    showApprove(user) {
      this.dialogApprove = true;
      this.approvePickupID = user.pickup_id;
    },
    async HandleApprove() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/approve_pickup",

          {
            pickup_id: this.approvePickupID,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Approve Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Approve Berhasil",
            icon: "success",
          });
          this.dialogApprove = false;
          this.getDataTable();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async showReject(user) {
      this.dialogReject = true;
      this.rejectPickupID = user.pickup_id;

      console.warn(user);
    },
    async HandleReject() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/reject_pickup",

          {
            pickup_id: this.rejectPickupID,
            keterangan_reject: this.keterangan_reject,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Reject Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Reject Berhasil",
            icon: "success",
          });
          this.dialogReject = false;
          this.getDataTable();

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
      } catch (err) {
        console.log(err);
      }
    },
    async HandlePembayaran() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/pembayaran",

          {
            pickup_id: this.edit_pembayaran_pickup_id,
            bukti_pembayaran: this.bukti_pembayaran,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.status);
        if (response.data.status === "fail") {
          swal({
            title: "Error",
            text: "Pembayaran Tidak Berhasil",
            icon: "warning",
          });
        } else {
          swal({
            title: "Success",
            text: "Pembayaran Berhasil",
            icon: "success",
          });
          this.dialogPembayaran = false;
          this.getDataTable();

          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
      } catch (err) {
        console.log(err);
      }
    },
    onBlur() {
      if (!this.selectedItem) {
        this.search = "";
      }
      if (!this.selectedEditDriver) {
        this.searcheditdriver = "";
      }
      if (!this.selectedProvinsi) {
        this.searchProvinsi = "";
      }
    },
    async searchItems() {
      this.loading = true;
      this.items = await this.fetchItemsFromDatabase(this.search);
      this.loading = false;
    },
    async searchEditDrivers() {
      this.loading = true;
      this.drivers = await this.fetchDriversFromDatabase(this.searcheditdriver);
      this.loading = false;
    },
    async searchProvinsis() {
      this.loading = true;
      this.provinsis = await this.fetchProvinsisFromDatabase(
        this.searchProvinsi,
        this.url,
        this.MediaKeys
      );
      this.loading = false;
    },
    async fetchProvinsisFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_provinsi", {
        params: { search },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },
    async HandleSubmit() {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": this.MediaKeys,
        };
        const response = await axios.post(
          this.url + "transaksi/get_t_pickup",
          {
            tgl_awal: this.tgl_awal,
            tgl_akhir: this.tgl_akhir,
            id: this.selectedItem,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data);
        if (response.data.status == "fail") {
          swal({
            title: "Error",
            text: "Data Tidak Tersedia",
            icon: "warning",
          });
        } else {
          this.harga = "";
          this.selectedLokasi = null;
          this.getDataTable();
          //this.$router.push("/presensi");
          //location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },

    Reset() {
      (this.search = ""),
        (this.searchlokasi = ""),
        (this.harga = ""),
        (this.tgl_awal = "");
    },
    kembali() {
      this.$router.push("/daftar");
    },

    async fetchItemsFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_status", {
        params: { search },
        headers: headers,
      });
      //console.warn(params)
      return response.data.data;
    },

    async fetchDriversFromDatabase(search) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": this.MediaKeys,
      };
      const response = await axios.get(this.url + "master/get_driver", {
        params: { search },
        headers: headers,
      });
      //console.warn(params)

      return response.data.data;
    },
  },
  formatRupiah(angka, prefix) {
    var separator;
    var number_string = angka
        .toString()
        .replace(/[^,\d]/g, "")
        .toString(),
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  },
  watch: {
    search: function () {
      this.searchItems();
    },
    searcheditdriver: function () {
      this.searchEditDrivers();
    },
    searchProvinsi: function () {
      this.searchProvinsis();
    },
  },
  computed: {
    totalRecords() {
      return this.items_table.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
};
</script>
