<template>
  <div class="logout">
    <v-container>
    
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data: () => ({}),
  created: function() {
    this.HandleLogout();
  },
  methods: {
    async HandleLogout() {
      try {
        localStorage.setItem("authenticated", false);
            localStorage.setItem("ID", "");
            localStorage.setItem("no_hp", "");
            localStorage.setItem("role_id", "");
            localStorage.setItem("name_users", "");
            localStorage.setItem("user_status", "");
            localStorage.setItem("provinsi_id","");
            localStorage.setItem("key","");
            localStorage.setItem("url","");
            localStorage.setItem("no_referal","");
            localStorage.setItem("otp","");
            localStorage.setItem("tokenFCM","");
        // this.$router.push("/");
        location.reload();
        //window.location.href = "https://salma.ptppi.co.id"
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
